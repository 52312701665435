/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item--has-icon {
    position: relative;
}

.accordion__title {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__title:hover {
    background-color: #ddd;
}

.accordion__body {
    padding: 20px;
    margin: 15px;
    display: block;
    animation: fadein 0.35s ease-in;
}

.accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
    margin-bottom: 0;
}

.accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
}

.accordion__arrow::after,
.accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
}

.accordion__arrow::before {
    left: 4px;
    transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before,
[aria-selected='true'] .accordion__arrow::before {
    transform: rotate(-45deg);
}

.accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after,
[aria-selected='true'] .accordion__arrow::after {
    transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes move-down {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

.accordion__title--animated:hover .accordion__arrow {
    animation-name: move-down;
    animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    animation-name: move-up;
    animation-duration: 1.5s;
}

.accordion__item .accordion__title[aria-selected='true'] div h4:before {
  content: '\2796';
  font-size: 1.25em;
  color: #777;
  float: right;
  margin-left: 5px;
}

.accordion__item .accordion__title[aria-selected='false'] div h4:before {
  content: '\2795';
  font-size: 1.25em;
  color: #777;
  float: right;
  margin-left: 5px;
}

.tags.loading > div {
    background-color: grey;
    color: white;
}

.tags {
    color: #4a634e;
    font-weight: 700;
}

.tags, .tags div {
    display: inline-block;
}

.tags > div {
    padding-left: 5px;
    margin: 0 3px 0 0;
    border-radius: 5px;
    background-color: #a95c42;
    color: white;
}

.tags > div > div {
    padding: 0 3px 0 0;
}

.tags input {
    background-color: transparent;
}

.tags-field.loading > div {
    background-color: grey;
    color: white;
}

.tags-field {
    color: ##000136;
    font-weight: 400;
}

.tags-field, .tags-field div {
    display: inline-block;
}

.tags-field > div {
    padding-left: 5px;
    margin: 0 3px 0 0;
    border-radius: 5px;
    background-color: #a95c42;
    color: white;
}

.tags-field > div > div {
    padding: 0 3px 0 0;
}

.tags-field input {
    background-color: transparent;
}

.remove {
    padding-right: 2px;
    margin-left: 8px;
    cursor: pointer;
}

td.fieldname {
  width: 25%;
}

td.fieldvalue {
  width: 75%;
}

.editable {
    background-color: yellow;
}

.invalid {
    outline-color: orangered !important;
}

.riek-text-input {
    color: #4a634e;
    font-weight: 700;
}

.riek-text-input-editing {
    color: #5c8727;
    font-weight: 400;
}

.riek-text-input-field {
    color: #000136;
    font-weight: 400;
}

.riek-text-input-field-editing {
    color: #4a634e;
    font-weight: 400;
}

/*! ========================================================================
 * Bootstrap Toggle: bootstrap-toggle.css v2.2.0
 * http://www.bootstraptoggle.com
 * ========================================================================
 * Copyright 2014 Min Hur, The New York Times Company
 * Licensed under MIT
 * ======================================================================== */


.checkbox label .toggle,
.checkbox-inline .toggle {
	margin-left: -20px;
	margin-right: 5px;
}

.toggle {
	position: relative;
	overflow: hidden;
}
.toggle input[type="checkbox"] {
	display: none;
}
.toggle-group {
	position: absolute;
	width: 200%;
	top: 0;
	bottom: 0;
	left: 0;
	transition: left 0.35s;
	-webkit-transition: left 0.35s;
	-moz-user-select: none;
	-webkit-user-select: none;
}
.toggle.off .toggle-group {
	left: -100%;
}
.toggle-on {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 50%;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.toggle-off {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	right: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.toggle-handle {
	position: relative;
	margin: 0 auto;
	padding-top: 0px;
	padding-bottom: 0px;
	height: 200%;
	width: 0px;
	border-width: 0 1px;
}

.toggle.btn { min-width: 59px; min-height: 34px; }
.toggle-on.btn { padding-right: 24px; }
.toggle-off.btn { padding-left: 24px; }

.toggle.btn-lg { min-width: 79px; min-height: 45px; }
.toggle-on.btn-lg { padding-right: 31px; }
.toggle-off.btn-lg { padding-left: 31px; }
.toggle-handle.btn-lg { width: 40px; }

.toggle.btn-sm { min-width: 50px; min-height: 30px;}
.toggle-on.btn-sm { padding-right: 20px; }
.toggle-off.btn-sm { padding-left: 20px; }

.toggle.btn-xs { min-width: 35px; min-height: 22px;}
.toggle-on.btn-xs { padding-right: 12px; line-height: 1.2em;}
.toggle-off.btn-xs { padding-left: 12px; line-height: 1.2em;}
