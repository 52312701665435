.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-weight: 500;
}

.Home .tables h1 {
  font-weight: 500;
}

span.text-size-h6 {
  font-weight: 400;
}

.Home .lander p {
  color: #999;
}
